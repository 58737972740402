.mat-mdc-dialog-container {
  --mdc-dialog-subhead-font: "Open Sans", sans-serif !important;
  --mdc-dialog-supporting-text-font: "Open Sans", sans-serif !important;

  padding: 0px !important;

  .mat-mdc-dialog-surface,
  .mdc-dialog__surface {
    box-shadow: none;
    padding: 25px;
    border-radius: 4px;
  }
}
