.mat-mdc-dialog-container {
  padding: 24px !important;
  color: var(--calenso-primary-color) !important;
  max-height: calc(100vh - 100px) !important;
  overflow-y: auto !important;

  .title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 0px !important;
  }

  .subtitle {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0px !important;
  }

  .close {
    color: var(--calenso-primary-color);
    opacity: 1;
  }

  .close {
    color: var(--calenso-primary-color);
    opacity: 1;

    span {
      font-size: 24px;
    }
  }

  .separator {
    margin-left: -24px !important;
    margin-right: -24px !important;
  }
}

@media (max-width: 500px) {
  .calenso-widget-dialog-box {
    max-width: 100vw !important;
    // max-height: 100vw !important;

    .mat-mdc-dialog-container {
      max-height: 100vh !important;
      min-height: 100vh !important;
      padding: 0px !important
    }

    .mobile-widget-header {
      padding-top: 16px;
      padding-left: 12px;
      padding-right: 12px;
    }

    .separator {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
  }
}

@media (max-width: 500px) {
  .calenso-generic-dialog-box {
    max-width: 100vw !important;
    // max-height: 100vw !important;

    .mat-mdc-dialog-container {
      max-height: 100vh !important;
      min-height: 100vh !important;
      // padding: 0px !important
    }

    .mobile-widget-header {
      padding-top: 16px;
      padding-left: 12px;
      padding-right: 12px;
    }

    .separator {
      //margin-left: 0px !important;
      //margin-right: 0px !important;
    }
  }
}

