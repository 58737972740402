.btn-primary {
  font-size: 14px !important;
  color: var(--calenso-white) !important;
  background-color: var(--calenso-primary-color) !important;
  border-color: var(--calenso-primary-color) !important;

  &:hover {
    color: var(--calenso-white) !important;
    background-color: var(--calenso-primary-color) !important;
    border-color: var(--calenso-primary-color) !important;
  }

  &:disabled {
    color: var(--calenso-white);
    background-color: var(--calenso-primary-color);
    border-color: var(--calenso-primary-color);
  }

  &:focus {
    box-shadow: none;
  }
}

.btn-outline-primary {
  font-size: 14px !important;
  color: var(--calenso-primary-color) !important;
  background-color: var(--calenso-white) !important;
  border-color: var(--calenso-primary-color) !important;

  &:hover {
    color: var(--calenso-white) !important;
    background-color: var(--calenso-primary-color) !important;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:focus {
    box-shadow: none;
  }
}

.btn-warn {
  font-size: 14px !important;
  color: var(--calenso-black) !important;
  background-color: var(--calenso-orange) !important;
  border-color: var(--calenso-orange) !important;

  &:hover {
    color: var(--calenso-black) !important;
    background-color: var(--calenso-orange) !important;
    border-color: var(--calenso-orange) !important;
  }

  &:disabled {
    color: var(--calenso-black) !important;
    background-color: var(--calenso-orange) !important;
    border-color: var(--calenso-orange) !important;
  }
}

.btn-danger {
  font-size: 14px !important;
  color: var(--calenso-white) !important;
  background-color: var(--calenso-red) !important;
  border-color: var(--calenso-red) !important;

  &:hover {
    color: var(--calenso-white) !important;
    background-color: var(--calenso-red) !important;
    border-color: var(--calenso-red) !important;
  }

  &:disabled {
    color: var(--calenso-white) !important;
    background-color: var(--calenso-red) !important;
    border-color: var(--calenso-red) !important;
  }

  &:focus {
    box-shadow: none;
  }
}

.btn-success {
  font-size: 14px !important;
  background-color: var(--calenso-green);
  border-color: var(--calenso-green);
  color: var(--calenso-white) !important;

  &:hover {
    background-color: var(--calenso-green);
    border-color: var(--calenso-green);
    color: var(--calenso-white) !important;
  }

  &:disabled {
    background-color: var(--calenso-green);
    border-color: var(--calenso-green);
    color: var(--calenso-white) !important;
  }

  &:focus {
    box-shadow: none;
  }
}

.mark-all-read-icon {
  color: var(--calenso-primary-color);
  font-size: 20px;
}

.btn-secondary {
  font-size: 14px !important;
  color: var(--calenso-primary-color) !important;
  background-color: var(--calenso-hawkes-blue) !important;
  border-color: var(--calenso-hawkes-blue) !important;

  &:hover {
    color: var(--calenso-primary-color) !important;
    background-color: var(--calenso-hawkes-blue) !important;
    border-color: var(--calenso-hawkes-blue) !important;
  }

  &:disabled {
    color: var(--calenso-primary-color);
    background-color: var(--calenso-hawkes-blue);
    border-color: var(--calenso-hawkes-blue);
  }

  &:focus {
    box-shadow: none;
  }
}

.btn-xs {
  font-size: 13px !important;
}

.btn-outline-light {
  font-size: 14px !important;
  color: var(--calenso-black) !important;
  background-color: var(--calenso-white) !important;
  border-color: var(--calenso-hawkes-blue) !important;

  &:hover {
    color: var(--calenso-black) !important;
    background-color: var(--calenso-white) !important;
    border-color: var(--calenso-hawkes-blue) !important;
  }

  &:disabled {
    color: var(--calenso-black) !important;
    background-color: var(--calenso-white) !important;
    border-color: var(--calenso-hawkes-blue) !important;
  }
}
