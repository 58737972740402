.menu-select {
  height: 35px;
  padding-left: 0px !important;
  min-width: 80px;

  .ng-select-container {
    border: none !important;
    border-radius: 8px !important;

    .ng-value-container {
      padding-left: 14px;
    }
  }

}

/*.menu-select .ng-select .ng-select-container {
  border: none !important;
  border-radius: 8px !important;
}*/

.transparent-action-menu-select {
  background-color: transparent !important;
}

.action-menu-select {
  cursor: pointer;
  background-color: white;
  border-radius: 8px;
  height: 40px;
  padding-top: 0px;
  // min-width: 92px;

  .dropdown-title {
    color: var(--calenso-primary-color);
    text-decoration: none;
    padding: 8px 15px;
  }

  .delete-item {
    color: var(--calenso-red);
  }

  .status-item {
    color: var(--calenso-primary-color);
    font-size: 14px;
  }
}

.action-menu-select::after {
  margin-left: 0;
  vertical-align: 0;
  content: "";
  border-top: 0 solid;
  border-right: 0 solid transparent;
  border-bottom: 0;
  border-left: 0 solid transparent;
}


