@import '_responsive.scss';

:root {
  --calenso-white: #FFFFFF;
  --calenso-light: #F8F9FA;
  --calenso-black: #000000;
  --calenso-black-shade-1: #2D333A;
  --calenso-gray: #808080;
  --calenso-light-gray: #DDDDDD;
  --calenso-light-gray-shade-2: #D3D3D3;
  --calenso-green: #97CC2E;
  --calenso-light-green: #C7EA71;
  --calenso-light-green-shade-2: #F2FFD7;
  --calenso-red: #EF6565;
  --calenso-light-red: #FFCBCB;
  --calenso-violet: #7676FF;
  --calenso-light-violet: #B1B2FD;
  --calenso-sky-blue: #68D6F0;
  --calenso-light-sky-blue: #EBF1FB;
  --calenso-light-blue: #F4F6FA;
  --calenso-dark-yellow: #856404;
  --calenso-yellow: #FFCB3D;
  --calenso-light-yellow: #FFFFE0;
  --calenso-orange: #FFA500;
  --calenso-light-orange: #FCDEA2;
  --calenso-hawkes-blue: #DFE1E9;
  --calenso-blueberry: #5555FF;
  --calenso-blueberry-light: #5555FF0F;
  --calenso-border-color: #CED4DA;
  --calenso-border-light-color: #CED4DA52;

  --calenso-primary-color: #3A4A66;
  --calenso-background-color: #A9B9D4;
  --calenso-slide-toggle-green-color: var(--calenso-green);

  --calenso-badge-success-bg: #DDFFDD;
  --calenso-badge-success: #4CAF50;
  --calenso-badge-warning-bg: #FDF6E0;
  --calenso-badge-warning: #F5B800;
  --calenso-badge-danger-bg: #FFDDDD;
  --calenso-badge-danger: #F44336;
  --calenso-badge-info-bg: #DDFFFF;
  --calenso-badge-info: #2196F3;
}
