.navbar {
  padding: 0.5rem 1rem !important;
}

.nav-tabs .nav-link {
  --bs-nav-link-hover-color: var(--calenso-violet);
  color: var(--calenso-violet);
}

.nav-pills .nav-link.active {
  background-color: var(--calenso-primary-color) !important;
}

.nav-pills .nav-link {
  --bs-nav-link-hover-color: var(--calenso-violet);
  color: var(--calenso-violet);
}
