.mat-mdc-radio-button {
  --mdc-radio-state-layer-size: 20px;

  &.mat-primary {
    --mdc-radio-selected-focus-icon-color: var(--calenso-primary-color);
    --mdc-radio-selected-hover-icon-color: var(--calenso-primary-color);
    --mdc-radio-selected-icon-color: var(--calenso-primary-color);
    --mdc-radio-selected-pressed-icon-color: var(--calenso-primary-color);
    --mat-mdc-radio-checked-ripple-color: var(--calenso-primary-color);
  }

  label {
    font-family: "Open Sans", sans-serif !important;
    white-space: normal !important;
    font-weight: 400;
    color: var(--calenso-primary-color);
  }

  .mat-radio-ripple {
    display: none;
  }
}
