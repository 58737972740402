.mat-mdc-form-field {
  display: flex !important;

  &.mat-focused .mat-form-field-ripple {
    background-color: inherit !important;
    opacity: 0 !important;
    transition: none !important;
  }

  .mat-mdc-form-field-flex {
    display: flex;
    align-items: center;
    line-height: 34px;
  }
  .mdc-text-field .mdc-floating-label {
    color: var(--calenso-black);
  }

  .mdc-floating-label {
    display: none;
  }

  .mdc-text-field--filled {
    background-color: var(--calenso-white);
    border-radius: 4px;
    border: 1px solid #cccccc;
  }

  &.mat-form-field-hide-placeholder {
    .mdc-floating-label {
      display: block;
    }
  }

  .mat-mdc-text-field-wrapper {
    height: 36px;

    &:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
      padding: 0px;
      height: 36px;
      max-height: 36px;
      min-height: 36px;
    }
    .mat-mdc-floating-label {
      top: 50%;
    }
  }
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
  .mat-mdc-form-field-focus-overlay {
    display: none;
  }
  .mat-mdc-form-field-icon-suffix {
    margin-top: -2px;

  }
  .mat-ripple {
    display: none;
  }
  .mdc-line-ripple {
    display: none;
  }
  .mdc-icon-button__ripple {
    display: none;
  }
  .mat-mdc-icon-button.mat-mdc-button-base {
    width: 36px;
    height: 36px;
    padding: 0 12px;
  }
  .mat-mdc-button-touch-target {
    display: none;
  }
}
