.breadcrumb-main {
  margin-top: 30px;
  margin-bottom: 40px;

  .breadcrumb {
    background-color: var(--calenso-white);
    border-radius: 8px;
    padding: 0.75rem 1rem !important;
  }
}
