.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  // min-height: 1.5rem;
  padding-left: 1.5rem;
  padding-top: 0.35rem ;
}

.custom-control-input {
    cursor: pointer;
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
    font-weight: 500;

}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
  width: 20px;
  height: 20px;
  position: absolute;
  left: -1.5rem;
  display: block;
  pointer-events: none;
  content: "";
  background-color: var(--calenso-white);
  border: 1px solid #adb5bd;

}
.custom-control-input:checked ~ .custom-control-label:before {
  color: var(--calenso-white);
  border-color: var(--calenso-primary-color);
  background-color: var(--calenso-primary-color);
  height: 20px;
  width: 20px;
}

.custom-checkbox .custom-control-label:after{
  position: absolute;
  top: 0;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url('../../assets/images/check-arrow.svg');
  border-radius: 0.25rem;
  height: 20px;
  width: 20px;
}
